<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="常用修理厂" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <span @click.stop="tocommonadd" class="liusui">
          <van-icon name="add-o" />
        </span>
      </template>
    </van-nav-bar>
    <div style="padding-top:46px; text-align: center;">
      <div class="lis" v-for="(item,idx) in list" :key="idx">
        <div class="left" style="font-weight: bold;">{{item.name}}</div>
        <div class="right she" v-if="item.isDefault == 0" @click="moren(item)">设为默认</div>
        <div class="right she" v-if="item.isDefault == 1">
          <span class="moren">默认</span>
        </div>
        <div class="right sc" @click="shanchu(item)">删除</div>
      </div>
    </div>
    <van-empty v-if="list.length < 1" description="请添加门店~" />
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: [],
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    moren(item) {
      let data = {
        gid: item.id,
         uid: this.userInfo.id,
        // uid: 1661
      };
      userModel
        .changedefault(data)
        .then(e => {
          sessionStorage.setItem("appid", item.idd);
          sessionStorage.setItem("gid", item.id);
          sessionStorage.setItem("shangjia", JSON.stringify(item));
          this.$toast.success("更换成功");
          this.onLoad();
        })
        .catch(() => loading.clear());
    },
    shanchu(item) {
      let data = {
        gid: item.id,
         uid: this.userInfo.id,
        // uid: 1661
      };
      userModel
        .deletegarage(data)
        .then(e => {
          this.$toast("删除成功");
          this.onLoad();
        })
        .catch(() => loading.clear());
    },
    tocommonadd() {
      this.$router.push({
        path: "commonadd",
         query: {
          isok: true
        }
      });
    },
    onLoad() {
      let data = {
         uid: this.userInfo.id,
        // uid: 1661
      };
      userModel
        .unamelist(data)
        .then(e => {
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "mine"
      // });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.liusui {
  font-size: 22px;
  margin-top: 5px;
}
.van-nav-bar .van-icon {
  color: #3ac29f;
}
.lis {
  font-size: 14px;
  overflow: hidden;
  margin: 0px 10px 0px 15px;
  padding: 12px 0;
  border-bottom: 1px solid #ebedf0;
  line-height: 25px;
}
.moren {
  display: inline-block;
  background: #0d906e;
  color: #fff;
  padding: 0 3px;
  line-height: 14px;
}
.sc {
  margin-right: 18px;
  color: red;
  font-size: 13px;
}
.she {
  width: 58px;
  text-align: center;
  font-size: 12px;
  color: #2a5caa;
}
</style>